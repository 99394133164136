import React, { PropsWithChildren, useEffect } from 'react';
import { MenuListProps } from 'react-select';

import './menu-list.scss';

function MenuList<Option>(props: PropsWithChildren<MenuListProps<Option>>) {
  const { children, focusedOption, maxHeight, selectOption } = props;

  useEffect(() => {
    function handleTabKey(e) {
      if (e.key === 'Tab' && focusedOption) {
        selectOption(focusedOption);
      }
    }

    window.addEventListener('keydown', handleTabKey);

    return () => {
      window.removeEventListener('keydown', handleTabKey);
    };
  }, [focusedOption, selectOption]);

  return (
    <div style={{ maxHeight }} styleName="container">
      {children}
    </div>
  );
}

export default MenuList;
