import React, { useState } from 'react';

import { CRUMBS, TABS } from '../../../../library/fitment/fitment-constants';
import FitmentByVehicle from '../../../../library/fitment/components/fitment-by-vehicle/fitment-by-vehicle';
import FitmentTabs from '../../../../library/fitment/components/fitment-tabs/fitment-tabs';
import FitmentTabHeader from '../../../fitment/components/fitment-tab-header/fitment-tab-header';

import './fitment-shell.scss';

export interface FitmentShellProps {
  hideTabs?: boolean;
  onFocusDropdown?: () => void;
  onTabFocus?: (tab: string) => void;
  onTabSelected?: (tab: string) => void;
  selectedVehicleCrumb?: string;
}

function FitmentShell(props: FitmentShellProps) {
  const { hideTabs, onFocusDropdown, onTabSelected, onTabFocus } = props;
  const [selectedVehicleCrumb, setSelectedVehicleCrumb] = useState(CRUMBS.YEAR);

  const year = String(new Date().getFullYear());

  return (
    <div>
      <FitmentTabs
        hideTabs={hideTabs}
        onTabSelected={onTabSelected}
        selectedTab={TABS.VEHICLE}
      />
      <div styleName={hideTabs ? 'content-no-tabs' : 'content'}>
        <FitmentByVehicle
          hideVehicleCrumbs={selectedVehicleCrumb === 'GENERIC'}
          isShowingRecentFitments
          onFocusDropdown={onFocusDropdown}
          renderAboveVehicleForm={() =>
            FitmentTabHeader({
              onTabFocus,
              setSelectedVehicleCrumb
            })
          }
          selectedCrumb={selectedVehicleCrumb}
          years={[
            {
              description: year,
              id: year
            }
          ]}
        />
      </div>
    </div>
  );
}

export default FitmentShell;
