import React, { lazy, Suspense, useEffect, useState } from 'react';

import { TABS } from '../../library/fitment/fitment-constants';
import FitmentShell from './components/fitment-shell/fitment-shell';

import { trackAction } from '../../shared/utils/analytics/analytics';
import { localStorage } from '../../shared/utils/storage';
import { isArrayWithLength } from '../../shared/utils/validators';

import {
  RECENT_VEHICLES_LS_KEY,
  SHOULD_REFRESH_USER_ON_APP_LOAD
} from '../../shared/constants/storage-keys';

const Fitment = lazy(async () => {
  const module = await import(
    /* webpackChunkName: "fitment" */
    '../fitment/fitment'
  );
  // slight delay to stop flicker during transition
  await new Promise(resolve => setTimeout(resolve, 50));

  return module;
});

function FitmentSmartLoader(props) {
  const [shouldLoadComponent, setShouldLoadComponent] = useState(() => {
    const isLoggedIn = localStorage(SHOULD_REFRESH_USER_ON_APP_LOAD);
    const recentVehicles = localStorage(RECENT_VEHICLES_LS_KEY);

    return isLoggedIn || isArrayWithLength(recentVehicles);
  });
  const [initialFitmentTab, setInitialFitmentTab] = useState(TABS.VEHICLE);
  const [shouldAutofocusYear, setShouldAutofocusYear] = useState(false);
  const [showGenericForm, setShowGenericForm] = useState(false);
  const [isNavigatingByKeyboard, setIsNavigatingByKeyboard] = useState(false);

  function onTabSelected(tab) {
    setInitialFitmentTab(tab);
    trackAction(`shop_page_by_${tab.toLowerCase()}`);
    setShouldLoadComponent(true);
  }

  function onFocusDropdown() {
    setShouldAutofocusYear(true);
    setShouldLoadComponent(true);
  }

  function onTabFocus(tab) {
    if (tab === 'GENERIC') {
      setShowGenericForm(true);
      setShouldLoadComponent(true);
    } else if (showGenericForm) {
      setShowGenericForm(false);
    }
  }

  useEffect(() => {
    function handleKeyDownEvent() {
      setIsNavigatingByKeyboard(true);
    }
    window.addEventListener('keydown', handleKeyDownEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, []);

  useEffect(() => {
    // TODO: Once lightweight JIT Fitment and MyAccount Providers and Hooks are added we can replace this
    const checkStorage = e => {
      const storageKey = e?.detail?.key;

      if (
        storageKey === SHOULD_REFRESH_USER_ON_APP_LOAD ||
        storageKey === RECENT_VEHICLES_LS_KEY
      ) {
        setShouldLoadComponent(true);
      }
    };

    window.addEventListener('setStorageItem', checkStorage, false);
    return () => {
      window.removeEventListener('setStorageItem', checkStorage, false);
    };
  }, [setShouldLoadComponent]);

  return (
    <>
      {!shouldLoadComponent && (
        <FitmentShell
          {...props}
          onFocusDropdown={onFocusDropdown}
          onTabFocus={onTabFocus}
          onTabSelected={onTabSelected}
        />
      )}

      {shouldLoadComponent && (
        <Suspense fallback={<FitmentShell {...props} />}>
          <Fitment
            isShowingRecentFitments={showGenericForm ? false : undefined}
            selectedVehicleCrumb={showGenericForm ? 'GENERIC' : undefined}
            {...props}
            isOptionsMenuOpenByDefault={
              !isNavigatingByKeyboard && shouldAutofocusYear
            }
            onTabFocus={onTabFocus}
            selectedTab={initialFitmentTab}
            shouldAutofocusYear={shouldAutofocusYear}
            showGenericForm={showGenericForm}
          />
        </Suspense>
      )}
    </>
  );
}

export default FitmentSmartLoader;
